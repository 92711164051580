<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 185px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center">
              <SelectFilter
                :label="$t('labels.bill')"
                :placeholder="$t('labels.bill')"
                name="company"
                sort-name="company"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :options="billOptions"
              />
            </th>
            <th class="text-center">
              <SelectFilter
                :label="$t('labels.invoice_type')"
                :placeholder="$t('labels.invoice_type')"
                name="invoice_type"
                sort-name="invoice_type"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :options="typeOptions"
              />
            </th>
            <th class="text-center">
              <InputFilter
                :label="$t('labels.seri')"
                :placeholder="$t('labels.seri')"
                name="invoice_seri"
                sort-name="invoice_seri"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <InputFilter
                label="App ID | Client Code"
                placeholder="App ID | Client Code"
                name="app_id"
                sort-name="app_id"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <InputFilter
                label="User ID | Proxy Code"
                placeholder="App ID | Proxy Code"
                name="app_id"
                sort-name="app_id"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <InputFilter
                label="Unit ID | Unit Code"
                placeholder="Unit ID | Unit Code"
                name="app_id"
                sort-name="app_id"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <!-- <th class="text-center">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th> -->
            <!-- <th class="text-center">
                            <InputFilter :label="$t('labels.tax_code')" :placeholder="$t('labels.tax_code')"
                                name="tax_code" sort-name="tax_code" has-sort :sorting="filters.sort_by"
                                @onFilter="onFilterChange" @onSort="onSortChange" />
                        </th> -->
            <!-- <th class="text-center">
              <SelectFilter
                :label="$t('labels.tax_code')"
                :placeholder="$t('labels.tax_code')"
                name="id_customer_tax_code"
                sort-name="id_customer_tax_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :options="taxCodeOptions"
              />
            </th> -->
            <th class="text-center">
              <InputFilter
                :label="$t('labels.username')"
                :placeholder="$t('labels.username')"
                name="username"
                sort-name="username"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <InputFilter
                :label="$t('labels.password')"
                :placeholder="$t('labels.password')"
                name="password"
                sort-name="password"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="min-width: 75px">
              {{ $t("labels.active") }}
            </th>
            <th class="text-center" style="min-width: 75px">
              {{ $t("labels.token") }}
            </th>
            <th class="text-center" style="width: 100px">
              <v-btn small color="primary" @click="addNewItem">
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, index) in items"
            :key="`c_${index}_${item.id}`"
          >
            <td>
              <v-autocomplete
                v-model="item.id_invoice_company"
                :items="billOptions"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                :label="$t('labels.bill')"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                v-model="item.invoice_type"
                :items="typeOptions"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-text-field
                v-model="item.invoice_seri"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="item.app_id"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="item.user_id"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="item.organization_unit_id"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <!-- <td>
              <v-text-field
                v-model="item.name"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                :placeholder="$t('labels.name')"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td> -->
            <!-- <td>
              <v-autocomplete
                v-model="item.id_customer_tax_code"
                :items="taxCodeOptions"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                :label="$t('labels.tax_code')"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td> -->
            <td>
              <v-text-field
                v-model="item.username"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="item.password"
                :disabled="!item.editing"
                type="password"
                class="c-input-small input-disabled-bold"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>

            <td class="text-center">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td>
              <template v-if="item.id">
                <span :class="{ 'error--text': !item.token_status }">
                  {{ $t(`labels.token_status_${item.token_status}`) }}
                </span>
                <br />
                <!-- <v-btn x-small color="primary">{{ $t('labels.connect') }}</v-btn> -->
              </template>
            </td>
            <td>
              <template v-if="!item.editing">
                <v-btn
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn class="mr-1" x-small color="success" @click="save(item)">
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { BILL_TYPE_COMPANY, INVOICE_TYPE } from "@/libs/const";
import moment from "moment";

export default {
  name: "Config",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  data: () => ({
    isLoading: false,
    filters: {},
    items: [],
    billOptions: [...BILL_TYPE_COMPANY],
    typeOptions: [...INVOICE_TYPE],
    taxCodeOptions: [],
  }),
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    addNewItem() {
      const checkActive = [...this.items].find((val) => val.id && val.status);
      if (checkActive) {
        this.$vToastify.error(this.$t("messages.active_1_config"));
        return false;
      }
      const item = {
        editing: true,
        id: null,
      };
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async getList() {
      try {
        const { data } = await httpClient.post(
          "/invoice-get-contract",
          this.filters
        );
        this.items = [...data.rows].map((item) => {
          let token_status = "";
          if (item.expired_at) {
            const now = moment().unix();
            const accessExpired = moment(item.expired_at).unix();
            token_status = accessExpired > now ? 1 : 0;
          }
          return {
            ...item,
            editing: false,
            token_status,
          };
        });
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },

    async save(item) {
      const checkActive = [...this.items].find(
        (val) => val.id && val.status && item.id != val.id
      );
      if (checkActive) {
        this.$vToastify.error(this.$t("messages.active_1_config"));
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/invoice-save-contract", item);
        this.isLoading = false;
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>
